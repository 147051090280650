<template>
	<div class="paginationWrp">
		<template v-for="(item, index) in compdata">
			<a v-if="item.type == 'prev'" :key="index" href="#" class="arrow" :class="{disabled: !item.s}" @click.prevent="$emit('click', 'prev')">
				<span class="ic-shevron-left"></span>
			</a>
			<a v-if="item.type == 'page'" :key="index" href="#" :class="{active: item.current}" @click.prevent="$emit('click', item.l)">{{item.l}}</a>
			<span v-if="item.type == 'dot'" :key="index" class="dots"><div></div></span>
			<a v-if="item.type == 'next'" :key="index" href="#" class="arrow" :class="{disabled: !item.s}" @click.prevent="$emit('click', 'next')">
				<span class="ic-shevron-right"></span>
			</a>
		</template>
	</div>
</template>

<script>
export default {
	name: 'pagination',
	props: ['compdata'],
}
</script>

<style lang="scss">
.paginationWrp {
	padding-top: 48px;
	display: flex;
	justify-content: flex-end;
	a {
		width: 42px;
		height: 42px;
		border-radius: 50%;
		font-style: normal;
		font-weight: 600;
		font-size: 15px;
		line-height: 42px;
		text-align: center;
		color: rgba(0, 0, 0, 0.8);
		margin: 0 3px;
		text-decoration: none;
		transition: 0.8s ease;
		@media (max-width: 375px) {
			margin: 0 1px;
		}
		&:hover {
			background: #e5e5ea;
		}
		&.active {
			background: #6cb6f1;
			color: #fff;
		}
	}
	.arrow {
		box-shadow: 0 0 0 1px inset #aeaeb2;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	*.disabled {
		pointer-events: none;
		opacity: 0.5;
	}
	.dots {
		width: 15px;
		position: relative;
		div {
			width: 3px;
			height: 3px;
			position: absolute;
			bottom: 5px;
			left: 50%;
			transform: translate(-50%, 0);
			background: #aeaeb2;
		}
		&::before {
			content: "";
			width: 3px;
			height: 3px;
			position: absolute;
			bottom: 5px;
			left: 0;
			background: #aeaeb2;
		}
		&::after {
			content: "";
			width: 3px;
			height: 3px;
			position: absolute;
			bottom: 5px;
			right: 0;
			background: #aeaeb2;
		}
	}
}
</style>