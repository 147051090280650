<template>
	<button class="emailbtn" :class="{pointered: state == 'normal', green: state != 'normal' && state != 'sending'}" @click="onClick">
		<transition name="emailsignbtn" mode="out-in">
			<div v-if="state == 'normal'" :key="'k1'">{{placeholder}}</div>
			<div v-else-if="state == 'sending'" :key="'k2'"><div class="lds-dual-ring"></div></div>
			<div v-else :key="'k3'"><img class="successimg" :src="require('@/assets/img/mailSignSuccess.svg')" alt="require('@/assets/img/MailSignSuccess.svg')"></div>
		</transition>
	</button>
</template>

<script>
export default {
	name: 'NewsSignBtn',
	props: ['state', 'placeholder'],
	data() {
		return {
			// state: 'normal', /sending /*
		}
	},
	methods: {
		onClick(){
			if(this.state == 'normal')
				this.$emit('click');
		}
	},
};
</script>

<style lang="scss" scoped>
.emailsignbtn-enter-active,
.emailsignbtn-leave-active {
	transition: 0.1s ease;
}
.emailsignbtn-enter {
	transform: translate(0px, 40px) !important;
}
.emailsignbtn-leave-to {
	transform: translate(0px, -40px) !important;
}
.lds-dual-ring {
	display: inline-block;
	width: 40px;
	height: 40px;
}
.lds-dual-ring:after {
	width: 24px;
	height: 24px;
	margin: 8px;
	border-radius: 50%;
	border: 2px solid #fff;
	border-color: #fff transparent #fff transparent;
}
.pointered{
	cursor: pointer;
}
.emailbtn{
	transition: 0.3s ease;
}
.green{
	background-color: #5DC2C2 !important;
}
.successimg{
	animation: successimgAnim 1.5s ease-in-out 0s 1 normal forwards;
	transform-origin: center bottom;
	@keyframes successimgAnim {
		0%{
			transform: rotate(-30deg);
		}
		20%{
			transform: rotate(30deg);
		}
		40%{
			transform: rotate(-15deg);
		}
		60%{
			transform: rotate(7deg);
		}
		80%{
			transform: rotate(-3deg);
		}
		100%{
			transform: rotate(0deg);
		}
	}
}
</style>