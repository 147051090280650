<template>
	<div class="layoutWrp">
		<MobHeaderMenu
			@menuswitch="$emit('menuswitch')"
			style="position: static"
		></MobHeaderMenu>
		<transition name="fadefast" mode="out-in">
			<div v-if="!loaded" class="loadingFiller" key="ld">
				<div class="lds-dual-ring"></div>
			</div>
			<div v-if="loaded" :key="'pagecontent'">
				<section class="newsPageSection">
					<div class="topHd">
						<router-link
							:to="{
								name: 'home',
								params: { locale: $i18n.locale == 'uk' ? null : $i18n.locale },
							}"
							class="backBtn"
						>
							<span class="ic-arrow-long-left"></span> {{ $t("menu.mainPage") }}
						</router-link>
						<a
							v-if="$store.getters.phone"
							:href="`tel:${$store.getters.phone.number}`"
							class="ph"
							>{{ $store.getters.phone.number }}</a
						>
					</div>
					<div class="newsSectionBlock">
						<img
							:src="path(constructor.subscribe.image)"
							:alt="path(constructor.subscribe.image)"
							class="topIllustration"
						/>
						<h1>{{ constructor.subscribe.title }}</h1>
						<div class="subTitle">{{ constructor.subscribe.subtitle }}</div>
						<div class="newsSearchWrp">
							<default-input
								:wrpstyle="{ flexGrow: 1 }"
								:placeholder="constructor.subscribe.input_placeholder"
								@change="onFieldChange"
								propname="email"
								:validate="$v.email"
								:showError="showError"
							></default-input>
							<news-sign-btn
								:state="sendBtnState"
								@click="onFormSend"
								:placeholder="constructor.subscribe.btn_name"
							></news-sign-btn>
						</div>
						<div class="newsItemsWrp">
							<router-link
								v-for="(item, index) in newsList"
								:key="index"
								:to="{
									name: `article`,
									params: {
										slug: item.model.slug,
										locale: $i18n.locale == 'uk' ? null : $i18n.locale,
									},
								}"
								class="item"
							>
								<div class="imgWrp">
									<img
										v-if="item.model.image"
										:src="path(item.model.image)"
										:alt="path(item.model.image)"
									/>
									<div class="stickers">
										<span
											v-if="item.model.youtube"
											class="ic-youtube vid"
										></span>
									</div>
								</div>
								<div class="txtWrp">
									<div class="date">{{ item.model.public_date }}</div>
									<div class="caption">{{ item.translate.name }}</div>
								</div>
							</router-link>
						</div>

						<pagination :compdata="pagiData" @click="onPagiClick"></pagination>
					</div>
				</section>
				<section class="newsBanner">
					<div class="newsBannerInner">
						<h2>{{ constructor.join_us.title }}</h2>
						<div class="bannerBody">
							<img :src="path(constructor.join_us.image)" alt="123" />
							<div class="g">
								<div class="t1">
									<span class="ic-curved-arrow"></span
									>{{ constructor.join_us.subtitle }}
								</div>
								<a :href="$store.getters.formLnk" target="_blank"
									><span class="ic-hand-1"></span
									>{{ constructor.join_us.btn_name }}</a
								>
							</div>
						</div>
					</div>
				</section>
			</div>
		</transition>
	</div>
</template>

<script>
import newsSignBtn from "@/components/news/NewsSignBtn.vue";

import DefaultInput from "@/components/common/inputs/DefaultInput.vue";
import MobHeaderMenu from "@/components/common/MobHeaderMenu.vue";
import Pagination from "@/components/news/Pagination.vue";
import { required, email } from "vuelidate/lib/validators";
export default {
	name: "NewsView",
	components: {
		MobHeaderMenu,
		DefaultInput,
		Pagination,
		newsSignBtn,
	},
	data() {
		return {
			loaded: false,
			constructor: null,
			showError: false,

			formSending: false,
			sendBtnState: "normal",

			email: "",

			newsList: null,
			currentPage: null,
			totalPage: null,
		};
	},
	methods: {
		onFieldChange(e) {
			this[e.name] = e.value;
		},
		onFormSend() {
			this.showError = true;
			if (!this.formSending && !this.$v.$invalid) {
				this.formSending = true;
				this.sendBtnState = "sending";
				this.axios
					.post("/api/news/subscribe", {
						email: this.email,
						source: document.title,
						agent_id: localStorage.getItem("agentId") || "",
					})
					.then(() => {
						this.sendBtnState = "success";
						this.formSending = false;
					})
					.catch((e) => {
						alert("error! see console");
						this.formSending = false;
						this.sendBtnState = "normal";
						console.log(e);
					});
			}
		},
		onPagiClick(p) {
			switch (p) {
				case "prev":
					this.loadNews(this.currentPage - 1);
					break;
				case "next":
					this.loadNews(this.currentPage + 1);
					break;
				default:
					this.loadNews(p);
					break;
			}
			window.scrollTo({
				top: 360,
				behavior: "smooth",
			});
		},
		loadNews(page) {
			if (page != this.currentPage) {
				this.axios
					.post("/api/news/all", {
						lang: this.$i18n.locale,
						page: page,
					})
					.then((response) => {
						this.newsList = response.data.data.models;
						this.constructor = response.data.data.constructor;
						this.currentPage = response.data.data.paginate.current_page;
						this.totalPage = Math.ceil(
							response.data.data.paginate.total /
								response.data.data.paginate.per_page
						);
						this.loaded = true;
					});
			}
		},
	},
	computed: {
		pagiData() {
			if (this.currentPage) {
				let p = [];
				if (this.currentPage > 1) p.push({ type: "prev", s: true });
				else p.push({ type: "prev", s: false });
				let pre = this.currentPage;
				if (pre > 4) {
					p.push({ type: "page", l: 1 });
					p.push({ type: "dot" });
					for (let i = this.currentPage - 2; i < this.currentPage; i++) {
						p.push({ type: "page", l: i, current: false });
					}
				} else {
					for (let i = 1; i < this.currentPage; i++) {
						p.push({ type: "page", l: i, current: false });
					}
				}
				let post = this.totalPage - this.currentPage;
				if (post > 4) {
					for (let i = this.currentPage; i < this.currentPage + 3; i++) {
						if (i == this.currentPage)
							p.push({ type: "page", l: i, current: true });
						else p.push({ type: "page", l: i, current: false });
					}
					p.push({ type: "dot" });
					p.push({ type: "page", l: this.totalPage });
				} else {
					for (let i = this.currentPage; i <= this.totalPage; i++) {
						if (i == this.currentPage)
							p.push({ type: "page", l: i, current: true });
						else p.push({ type: "page", l: i, current: false });
					}
				}
				if (this.currentPage < this.totalPage)
					p.push({ type: "next", s: true });
				else p.push({ type: "next", s: false });
				return p;
			}
			return null;
		},
	},
	created() {
		this.axios
			.post("/api/news/all", {
				lang: this.$i18n.locale,
				page: 1,
			})
			.then((response) => {
				if (this.$store.getters.newsTitle)
					document.title = this.$store.getters.newsTitle;
				this.newsList = response.data.data.models;
				this.constructor = response.data.data.constructor;
				this.currentPage = response.data.data.paginate.current_page;
				this.totalPage = Math.ceil(
					response.data.data.paginate.total /
						response.data.data.paginate.per_page
				);
				this.loaded = true;
			});
	},
	validations() {
		return {
			email: {
				required,
				email,
			},
		};
	},
};
</script>

<style lang="scss">
.layoutWrp {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}

.newsPageSection {
	flex-grow: 1;
	background-color: #f8f8f8;
	padding: 31px 48px 0;
	@media (max-width: 1365px) {
		padding: 31px 24px 0;
	}
	@media (max-width: 575px) {
		padding: 31px 16px 0;
	}
	.newsSectionBlock {
		max-width: 1000px;
		margin: auto;
		padding-bottom: 80px;
		.topIllustration {
			display: block;
			margin: auto;
			max-width: 100%;
			@media (max-width: 575px) {
				margin-top: 40px;
			}
		}
		h1 {
			font-style: normal;
			font-weight: 800;
			font-size: 55px;
			line-height: 110%;
			text-align: center;
			color: #6cb6f1;
			margin: 24px auto 0;
			max-width: 750px;
			@media (max-width: 1023px) {
				font-size: 38px;
				line-height: 110%;
			}
			@media (max-width: 575px) {
				font-size: 28px;
				line-height: 110%;
			}
		}
		.inputWrap {
			input:focus {
				box-shadow: 0 0 0 1px inset #6cb6f1;
			}
		}
		.subTitle {
			font-style: normal;
			font-weight: 600;
			font-size: 20px;
			line-height: 160%;
			text-align: center;
			color: #000000;
			@media (max-width: 1199px) {
				margin-top: 16px;
			}
		}
		.newsSearchWrp {
			display: flex;
			align-items: flex-start;
			@media (max-width: 1023px) {
				flex-direction: column;
			}
			.mainInpputWrap {
				background-color: #f8f8f8;
				margin: 16px 0;
				@media (max-width: 1023px) {
					width: 100%;
				}
				flex-grow: 1;
			}
			button {
				margin: 16px 0;
				font-style: normal;
				font-weight: 600;
				font-size: 16px;
				line-height: 150.3%;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #ffffff;
				background: #6cb6f1;
				border-radius: 1px;
				height: 62px;
				border: none;
				width: 324px;
				margin-left: 16px;
				@media (max-width: 1023px) {
					width: 100%;
					margin-left: 0;
				}
			}
		}
		.newsItemsWrp {
			margin-top: 135px;
			@media (max-width: 1023px) {
				margin-top: 74px;
			}
			@media (max-width: 767px) {
				display: flex;
				flex-wrap: wrap;
			}
			.item {
				display: flex;
				text-decoration: none;
				& + .item {
					margin-top: 35px;
					@media (max-width: 767px) {
						margin-top: 0;
					}
				}
				@media (max-width: 767px) {
					flex-direction: column;
					width: 50%;
					padding: 0 8px;
					box-sizing: border-box;
					padding-bottom: 16px;
					margin-bottom: 32px;
					// box-shadow: 0 1px 0 0 #AEAEB2;
					position: relative;
					&:after {
						content: "";
						position: absolute;
						bottom: 0;
						left: 8px;
						right: 8px;
						height: 1px;
						background-color: #aeaeb2;
					}
				}
				@media (max-width: 575px) {
					width: 100%;
				}
				.imgWrp {
					width: 396px;
					height: 264px;
					position: relative;
					flex-shrink: 0;
					margin-right: 64px;
					@media (max-width: 1365px) {
						width: 222px;
						height: 148px;
						margin-right: 32px;
					}
					@media (max-width: 767px) {
						width: 100%;
						height: 240px;
						margin-right: 0;
					}
					img {
						width: 100%;
						height: 100%;
						display: block;
						object-fit: cover;
						position: relative;
						z-index: 1;
					}
					.stickers {
						position: absolute;
						z-index: 5;
						bottom: 8px;
						right: 8px;
						display: flex;
						.vid {
							width: 46px;
							height: 46px;
							border-radius: 50%;
							background: #6cb6f1;
							font-size: 19px;
							color: #fff;
							display: flex;
							justify-content: center;
							align-items: center;
						}
					}
				}
				.txtWrp {
					@media (max-width: 767px) {
						margin-top: 8px;
					}
					.date {
						font-style: normal;
						font-weight: 600;
						font-size: 14px;
						line-height: 110%;
						color: #8e8e93;
					}
					.caption {
						font-style: normal;
						font-weight: 600;
						font-size: 28px;
						line-height: 135%;
						color: #4a4a4b;
						margin-top: 10px;
						transition: 0.3s ease;
						@media (max-width: 1365px) {
							font-size: 22px;
							line-height: 135%;
						}
					}
				}
				&:hover {
					.txtWrp {
						.caption {
							color: #6cb6f1;
						}
					}
				}
			}
		}
	}
}
.newsBanner {
	background-color: #f8f8f8;
	background-position: top right;
	padding: 80px 48px 0;
	@media (max-width: 1365px) {
		padding: 80px 24px 0;
	}
	@media (max-width: 575px) {
		padding: 80px 16px 0;
	}
	position: relative;
	&:before {
		content: "";
		position: absolute;
		z-index: 1;
		top: -2px;
		bottom: -2px;
		left: 0;
		right: 0;
		border-radius: 193px 0px 0 0px;
		background-color: #f2f2f2;
		@media (max-width: 1365px) {
			border-radius: 160px 0px 0 0px;
		}
		@media (max-width: 1023px) {
			border-radius: 0px 0px 0 0px;
		}
	}
	& > * {
		position: relative;
		z-index: 5;
	}
	.newsBannerInner {
		display: flex;
		flex-direction: column;
		align-items: center;
		max-width: 1000px;
		margin: auto;
	}
	h2 {
		width: 100%;
		font-style: normal;
		font-weight: 600;
		font-size: 86px;
		line-height: 110%;
		color: #6cb6f1;
		margin: 0;
		text-align: left;
		@media (max-width: 1023px) {
			font-size: 68px;
			line-height: 110%;
		}
		@media (max-width: 575px) {
			font-size: 38px;
			line-height: 110%;
		}
	}
	.bannerBody {
		padding: 135px 0;
		display: flex;
		align-items: center;
		@media (max-width: 1023px) {
			flex-direction: column;
			align-items: center;
			padding: 80px 0;
		}
		img {
			margin-right: 80px;
			max-width: 100%;
			@media (max-width: 1023px) {
				margin: auto;
				margin-bottom: 48px;
			}
		}
		.g {
			.t1 {
				font-style: normal;
				font-weight: 600;
				font-size: 34px;
				line-height: 43px;
				color: #000000;
				@media (max-width: 575px) {
					font-size: 24px;
					margin-top: 48px;
				}
				.ic-curved-arrow {
					margin-right: 10px;
					font-size: 46px;
					vertical-align: middle;
				}
			}
			a {
				width: 324px;
				height: 64px;
				border-radius: 1px;
				text-align: center;
				font-style: normal;
				font-weight: 600;
				font-size: 16px;
				line-height: 150.3%;
				color: #ffffff;
				background-color: #e10b17;
				display: flex;
				align-items: center;
				justify-content: center;
				text-decoration: none;
				margin-top: 20px;
				transition: 0.8s cubic-bezier(0.76, -0.51, 0.29, 1.6);
				border: none;
				@media (max-width: 575px) {
					width: 100%;
				}
				&:hover {
					background-color: #c4141d;
				}
				.ic-hand-1 {
					font-size: 32px;
					margin-right: 33px;
				}
			}
		}
	}
}
</style>