<template>
	<label
		:for="`input${_uid}`"
		class="mainInpputWrap"
		:class="{ isError: isError }"
		:style="wrpstyle"
	>
		<div class="inpputWrap">
			<template v-if="!mask">
				<input
					:id="`input${_uid}`"
					:type="itype ? itype : 'text'"
					v-model.trim="value"
					@keyup="onKeyUp"
				/>
			</template>
			<template v-else>
				<the-mask
					:id="`input${_uid}`"
					:mask="mask ? mask : ''"
					:masked="true"
					v-model.trim="value"
					@keyup.native="onKeyUp"
				/>
			</template>
			<div class="labelWrp" :class="{ active: value != '' }">
				<div class="pre" ref="pre"></div>
				<div class="center" :style="{ width: `${labelWidth}px` }">
					<span class="labelspan" ref="labelspan">{{ placeholder }}</span>
				</div>
				<div class="end"></div>
			</div>
		</div>
		<span class="tip" v-if="isError">{{ isError }}</span>
	</label>
</template>

<script>
import { TheMask } from "vue-the-mask";

export default {
	name: "DefaultInput",
	components: {
		TheMask,
	},
	props: [
		"mask",
		"placeholder",
		"itype",
		"errors",
		"propname",
		"validate",
		"showError",
		"wrpstyle",
		"prevalue",
	],
	data() {
		return {
			value: "",
			labelWidth: 0,
		};
	},
	methods: {
		onKeyUp() {
			this.$emit("change", { name: this.propname, value: this.value });
		},
	},
	computed: {
		isMaskedInput() {
			if (this.mask !== undefined && this.mask !== null && this.mask !== "")
				return true;
			else return false;
		},
		isError() {
			if (this.validate.$invalid && this.showError) {
				if (this.validate.required === false) {
					return this.$t("fieldErrors.required");
				}
				if (this.validate.minLength === false) {
					return this.$t("fieldErrors.minLength", {
						length: this.validate.$params.minLength.min,
					});
				}
				if (this.validate.email === false) {
					return this.$t("fieldErrors.email");
				}
				return "some error";
			} else return null;
		},
	},
	mounted() {
		this.labelWidth = this.$refs.labelspan.clientWidth + 4;
		setTimeout(() => {
			this.value = this.prevalue ? this.prevalue : "";
		}, 200);
	},
};
</script>

<style scoped lang='scss'>
.mainInpputWrap {
	margin: 16px 0;
	display: block;
	.inpputWrap {
		// background-color: #f8f8f8;
		// box-shadow: 0 0 0 1px red;
		display: block;
		position: relative;
		input {
			height: 64px;
			padding: 0 24px;
			width: 100%;
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 20px;
			color: #000000;
			border: none;
			box-sizing: border-box;
			outline: none;
			background: transparent;
		}
		.labelWrp {
			pointer-events: none;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			display: flex;
			.pre {
				top: 0;
				bottom: 0;
				left: 0;
				width: 24px;
				border-top: 1px solid #8e8e93;
				border-left: 1px solid #8e8e93;
				border-bottom: 1px solid #8e8e93;
				box-sizing: border-box;
			}
			.center {
				border-top: 1px solid #8e8e93;
				border-bottom: 1px solid #8e8e93;
				position: relative;
				box-sizing: border-box;
				.labelspan {
					font-style: normal;
					font-weight: 600;
					font-size: 16px;
					line-height: 20px;
					color: #8e8e93;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					white-space: nowrap;
					transition: 0.1s ease;
				}
			}
			.end {
				border-top: 1px solid #8e8e93;
				border-right: 1px solid #8e8e93;
				border-bottom: 1px solid #8e8e93;
				flex-grow: 1;
				box-sizing: border-box;
			}
		}
		input:focus ~ .labelWrp {
			.pre {
				border-top: 2px solid #000;
				border-left: 2px solid #000;
				border-bottom: 2px solid #000;
			}
			.center {
				border-top: none;
				border-bottom: 2px solid #000;
				.labelspan {
					top: 0;

					font-size: 15px;
					line-height: 19px;
					color: #000000;
				}
			}
			.end {
				border-top: 2px solid #000;
				border-right: 2px solid #000;
				border-bottom: 2px solid #000;
			}
		}
		.labelWrp.active {
			.pre {
				border-top: 1px solid #8e8e93;
				border-left: 1px solid #8e8e93;
				border-bottom: 1px solid #8e8e93;
			}
			.center {
				border-top: none;
				border-bottom: 1px solid #8e8e93;
				.labelspan {
					top: 0;

					font-size: 15px;
					line-height: 19px;
					color: #000000;
				}
			}
			.end {
				border-top: 1px solid #8e8e93;
				border-right: 1px solid #8e8e93;
				border-bottom: 1px solid #8e8e93;
			}
		}
	}
	&.isError {
		.labelWrp {
			.pre {
				border-color: #ff7051 !important;
			}
			.center {
				border-color: #ff7051 !important;
				.labelspan {
					color: #ff7051 !important;
				}
			}
			.end {
				border-color: #ff7051 !important;
			}
		}
	}
	.tip {
		display: block;
		font-style: normal;
		font-weight: 600;
		font-size: 15px;
		line-height: 19px;
		color: #ff7051;
		margin: 4px 16px;
	}
}
</style>