var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layoutWrp"},[_c('MobHeaderMenu',{staticStyle:{"position":"static"},on:{"menuswitch":function($event){return _vm.$emit('menuswitch')}}}),_c('transition',{attrs:{"name":"fadefast","mode":"out-in"}},[(!_vm.loaded)?_c('div',{key:"ld",staticClass:"loadingFiller"},[_c('div',{staticClass:"lds-dual-ring"})]):_vm._e(),(_vm.loaded)?_c('div',{key:'pagecontent'},[_c('section',{staticClass:"newsPageSection"},[_c('div',{staticClass:"topHd"},[_c('router-link',{staticClass:"backBtn",attrs:{"to":{
							name: 'home',
							params: { locale: _vm.$i18n.locale == 'uk' ? null : _vm.$i18n.locale },
						}}},[_c('span',{staticClass:"ic-arrow-long-left"}),_vm._v(" "+_vm._s(_vm.$t("menu.mainPage"))+" ")]),(_vm.$store.getters.phone)?_c('a',{staticClass:"ph",attrs:{"href":("tel:" + (_vm.$store.getters.phone.number))}},[_vm._v(_vm._s(_vm.$store.getters.phone.number))]):_vm._e()],1),_c('div',{staticClass:"newsSectionBlock"},[_c('img',{staticClass:"topIllustration",attrs:{"src":_vm.path(_vm.constructor.subscribe.image),"alt":_vm.path(_vm.constructor.subscribe.image)}}),_c('h1',[_vm._v(_vm._s(_vm.constructor.subscribe.title))]),_c('div',{staticClass:"subTitle"},[_vm._v(_vm._s(_vm.constructor.subscribe.subtitle))]),_c('div',{staticClass:"newsSearchWrp"},[_c('default-input',{attrs:{"wrpstyle":{ flexGrow: 1 },"placeholder":_vm.constructor.subscribe.input_placeholder,"propname":"email","validate":_vm.$v.email,"showError":_vm.showError},on:{"change":_vm.onFieldChange}}),_c('news-sign-btn',{attrs:{"state":_vm.sendBtnState,"placeholder":_vm.constructor.subscribe.btn_name},on:{"click":_vm.onFormSend}})],1),_c('div',{staticClass:"newsItemsWrp"},_vm._l((_vm.newsList),function(item,index){return _c('router-link',{key:index,staticClass:"item",attrs:{"to":{
								name: "article",
								params: {
									slug: item.model.slug,
									locale: _vm.$i18n.locale == 'uk' ? null : _vm.$i18n.locale,
								},
							}}},[_c('div',{staticClass:"imgWrp"},[(item.model.image)?_c('img',{attrs:{"src":_vm.path(item.model.image),"alt":_vm.path(item.model.image)}}):_vm._e(),_c('div',{staticClass:"stickers"},[(item.model.youtube)?_c('span',{staticClass:"ic-youtube vid"}):_vm._e()])]),_c('div',{staticClass:"txtWrp"},[_c('div',{staticClass:"date"},[_vm._v(_vm._s(item.model.public_date))]),_c('div',{staticClass:"caption"},[_vm._v(_vm._s(item.translate.name))])])])}),1),_c('pagination',{attrs:{"compdata":_vm.pagiData},on:{"click":_vm.onPagiClick}})],1)]),_c('section',{staticClass:"newsBanner"},[_c('div',{staticClass:"newsBannerInner"},[_c('h2',[_vm._v(_vm._s(_vm.constructor.join_us.title))]),_c('div',{staticClass:"bannerBody"},[_c('img',{attrs:{"src":_vm.path(_vm.constructor.join_us.image),"alt":"123"}}),_c('div',{staticClass:"g"},[_c('div',{staticClass:"t1"},[_c('span',{staticClass:"ic-curved-arrow"}),_vm._v(_vm._s(_vm.constructor.join_us.subtitle)+" ")]),_c('a',{attrs:{"href":_vm.$store.getters.formLnk,"target":"_blank"}},[_c('span',{staticClass:"ic-hand-1"}),_vm._v(_vm._s(_vm.constructor.join_us.btn_name))])])])])])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }